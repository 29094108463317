
import Vue from 'vue';
import utils from '../../../util';

type SiteImpact = {
  name: string;
  apiKey: string;
};

export default Vue.extend({
  components: {
    linkedType: () => import('../_linkedTypeSI.vue'),
  },
  data: (): {
    error: string | null;
    isAgency: boolean;
    agencyConnections: SiteImpact[] | null;
    headers: Array<object>;
    dialog: boolean;
    isEdit: boolean;
    valid: boolean;
    keyId: string | null;
    name: string | null;
    oldName: string | null;
    apiKey: string | null;
    enabled: boolean;
    showDeleteConfirmation: boolean;
    itemToDelete: SiteImpact | null;
    validKey: Array<Function>;
  } => ({
    isAgency: true,
    agencyConnections: null,
    headers: [
      {
        text: '',
        sortable: false,
      },
      {
        text: 'Ads',
        sortable: false,
      },
      {
        text: '',
        sortable: false,
      },
    ],
    error: null,
    dialog: false,
    isEdit: false,
    valid: false,
    keyId: null,
    name: null,
    oldName: null,
    apiKey: null,
    enabled: false,
    showDeleteConfirmation: false,
    itemToDelete: null,
    validKey: [
      v => !!v || 'Site Impact API Key is required',
      v =>
        /^\S[a-zA-Z0-9-]*$\S{0,}/g.test(v) ||
        'Site Impact API Key must be valid (letters, numbers, dashes). No spaces or special characters.',
      v => (v && v.length > 3) || 'API Key must be longer than 3 characters',
    ],
  }),
  created(): void {
    this.loadSiteImpactData();
  },
  methods: {
    async loadSiteImpactData(): Promise<void> {
      try {
        const resp = await this.$store.dispatch('agencyAdmin/getSiteImpactCredential', {
          id: this.agencyId,
        });
        const filteredData = resp?.filter((item: SiteImpact) => Object.keys(item).length > 0);
        this.agencyConnections = filteredData;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('loadSiteImpactData error', exp);
      }
    },
    async remove(name): Promise<void> {
      try {
        const deleted = await this.$store.dispatch('agencyAdmin/removeSiteImpactCredential', {
          id: this.agencyId,
          name: name,
        });
        if (deleted) {
          this.loadSiteImpactData();
          this.itemToDelete = null;
          this.showDeleteConfirmation = false;
        }
      } catch (exp) {
        this.error = this.$store.state.agencyAdmin.siteImpactError;
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    },
    async add(): Promise<void> {
      try {
        const added = await this.$store.dispatch('agencyAdmin/addSiteImpactCredential', {
          id: this.agencyId,
          apiKey: this.apiKey,
          name: this.name,
        });
        if (added) {
          this.loadSiteImpactData();
          this.clear();
        }
      } catch (exp) {
        this.error = this.$store.state.agencyAdmin.siteImpactError;
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    },
    async update(): Promise<void> {
      try {
        const updated = await this.$store.dispatch('agencyAdmin/editSiteImpactCredential', {
          id: this.agencyId,
          apiKey: this.apiKey,
          name: this.name,
          oldName: this.oldName,
        });
        if (updated) {
          this.loadSiteImpactData();
          this.clear();
        }
      } catch (exp) {
        this.error = this.$store.state.agencyAdmin.siteImpactError;
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    },
    edit(item: SiteImpact, name: string): void {
      this.oldName = name;
      this.dialog = true;
      this.isEdit = true;
      this.name = item.name;
      this.apiKey = item.apiKey;
    },
    showDeleteDialog(item: SiteImpact): void {
      this.itemToDelete = item;
      this.showDeleteConfirmation = true;
    },
    hasIndividualRight(roles: Array<string>): boolean {
      return utils.hasRight(this.$store.state.customer.user, roles);
    },
    resetValidation(): void {
      this.$refs.addConnection.resetValidation();
    },
    validate(): void {
      if (this.$refs.addConnection.validate()) {
        if (this.activeApiKeys.includes(this.apiKey) && !this.isEdit) return;
        if (this.isEdit) this.update();
        else this.add();
      }
    },
    clear(): void {
      this.error = null;
      this.dialog = false;
      this.isEdit = false;
      this.oldName = null;
      this.name = null;
      this.apiKey = null;
      this.resetValidation();
    },
  },
  computed: {
    activeApiKeys(): Array<string> {
      return this.filteredConnections.map(connection => connection.apiKey);
    },
    agencyId(): string {
      return this.$route.params.agency;
    },
    filteredConnections(): Array<object> {
      return this.agencyConnections ? this.agencyConnections : [];
    },
    connections(): boolean {
      return this.filteredConnections.length > 0;
    },
    dialogTitle(): string {
      return this.isEdit ? `Edit ${this.name || 'connection'}` : 'Add new connection';
    },
  },
});
